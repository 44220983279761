<app-header></app-header>

<div class="select-option ps-3 pe-3">
  <div class="container">
    <div class="row pt-sm-3 pt-md-5 pt-lg-5 pb-5">
      <div class="col-12 col-lg-6 about-top-left ps-0 pe-0 pt-5 ps-md-3 pe-md-3 p-lg-5">
        <div>
          <div>
            <h1 class="about-heading">
              At Spruce, we focus on renewable power. We want the world to
              be a better place and put sustainability first.
            </h1>
          </div>
          <hr />
          <div>
            <p>
              Spruce Power is a renewable focused Power-as-a-Service
              company. With roots in both energy efficiency and residential
              solar systems, our next generation product offerings will
              enable homeowners to securely store and supply power to their
              homes with affordable renewable energy. We are one of the
              first solar companies to offer retrofit home batteries and we
              are continuously evaluating partnerships to bring advanced EV
              chargers and smart home energy management devices to our
              customers. The future of power is distributed, clean and
              smart. Are you coming with us?
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 p-0">
        <div class="right-image">
          <img class="w-100" src="/assets/garden.jpg" alt="garden-image"/>
        </div>
      </div>
      <div class="col-12 col-lg-6 pt-4 pt-lg-0 ps-0 pe-0">
        <div class="right-image">
          <img class="w-100" src="/assets/solorPallets.jpg" alt="solar-pallets-image"/>
        </div>
      </div>
      <div class="col-12 col-lg-6 pt-5 ps-0 pe-0 ps-md-3 pe-md-3 p-lg-5 about-top-left">
        <div>
          <div>
            <h1 class="about-heading">Integrity. Safety.<br /> Accountability.<br /> Collaboration.</h1>
          </div>
          <hr />
          <div>
            <p>
              At Spruce, we are safety conscious, and we strive to ensure
              that our products meet or exceed industry standards for safety
              and quality. We take our values seriously, and hold our over
              100 qualified service partners and trained in-house service
              experts accountable to ensure your system delivers the
              benefits you expect. Spruce takes a collaborative approach to
              working with our customers and partners to deliver mutually
              beneficial solutions to each of our stakeholders.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 mt-5">
        <div class="defend-section">
          <div class="container">
            <div class="inner-content-text">
              <h1 class="about-heading">What we defend.</h1>
              <div class="defend-paragraph">
                <p>We are committed to all aspects of sustainability.</p>
                <p>&nbsp;</p>
                <p>
                  We are proud to offer a living wage of at least $17 per
                  hour to all Spruce Power and affiliate employees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 ps-0 pe-0 pt-4 pb-4">
        <div class="et_pb_text_inner">
          <h1 class="about-heading">Our Executive Team</h1>
          <hr />
        </div>
      </div>
      <div class="col-12 ps-0 pe-0 pb-lg-5">
        <div class="row inner-Executive-section ms-0 me-0">
          <div class="col-12 col-lg-6 p-0">
            <div class="image1">
              <div class="Executive_text_inner">
                <h1 class="about-heading">Corporate Execs</h1>
                <hr />
                <p>&nbsp;</p>
                <p>
                  Chris Hayes (Chairman, CEO, President)<br />
                  Jon Norling (Chief Legal Officer)<br />
                  Sarah Wells (CFO, Head of Sustainability)<br />
                  Joe Pettit (Head of Corporate Development)<br />
                  Derick Smith (Chief Operating Officer)<br />
                  Rich DiMatteo (Head of Spruce Pro&ZeroWidthSpace;)
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 p-0 mt-4 mt-lg-0">
            <div class="image2">
              <div class="Executive_text_inner">
                <h1 class="about-heading">Executives</h1>
                <hr />
                <p>&nbsp;</p>
                <p>
                  Daniel Garcia<br />Kevin Minton<br />Sophia Washington
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
